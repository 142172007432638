<template>
  <div id="homePage">
    <Header />
    <!-- <HomeCategories /> -->
    <specialCategories/>
    <RecommendedProductSlider
    class="d-none d-md-block"
      title="جدیدترین های آنجل"
      :products="new_products"
      :link="'/products?sort=newest'"
      v-if="new_products"
    />
    <AmazingDiscount
      :products="mostDiscount"
      :link="'/products?sort=most_discount'"
      v-if="mostDiscount"
    />
    <Banners class="d-none d-md-flex" :banners="banners[0]"></Banners>

    <RecommendedProductSlider
    class="d-none d-md-block"
      title="پیشنهاد آنجل"
      :products="suggestions"
      :link="'/products?sort=suggest'"
      v-if="suggestions"
    />
    <RecommendedProductSlider
    class="d-none d-md-block"
      title="پرفروش ترین ها در آنجل"
      :products="mostSales"
      :link="'/products?sort=top_sales'"
      v-if="mostSales"
    />
   
    <!-- <MainBanners/> -->

    <!-- <RecommendedProduct /> -->
    <!-- <Newbanner/> -->
    <main class="main">
      <Flashes
        v-if="
          $store.getters['front/getHomeData'] != null &&
            $store.getters['front/getHomeData'].flashes != ''
        "
        :flashes="$store.getters['front/getHomeData'].flashes"
      />
      <Weblogs />

      <!-- <LastInstaPosts class="mt-4 mt-sm-5 " /> -->
    </main>
  </div>
</template>

<script>
import Header from "@/parts/Front/components/shared/Header";
import Banners from "@/parts/Front/components/Banners";
import Flashes from "@/parts/Front/components/FlashProducts";
import Weblogs from "@/parts/Front/components/Weblogs";
import LastInstaPosts from "@/parts/Front/components/LastInstaPosts";
import ScrollToTop from "@/parts/Front/components/ScrollToTop";
import debounce from "lodash/debounce";
import ProductCategories from "@/parts/Front/components/ProductCategories";
import AboutHome from "@/parts/Front/components/home/AboutHome";
import RecommendedProductSlider from "@/parts/Front/components/RecommendedProductSlider";
import SingleBanner from "@/parts/Front/components/SingleBanner";
import ResponsiveBanner from "@/parts/Front/components/ResponsiveBanner";
import Services from "@/parts/Front/components/Services";
import HomeCategories from "@/parts/Front/nicecomponents/HomeCategories";
// import RecommendedProduct from "@/parts/Front/components/RecommendedProduct";
import ResponsiveBannerDouble from "@/parts/Front/components/ResponsiveBannerDouble";
import Newbanner from "../components/header/Newbanner.vue";
import MainBanners from "@/parts/Front/components/home/MainBanners.vue";
import specialCategories from '@/parts/Front/components/SpecialCategories.vue'
import AmazingDiscount from "@/parts/Front/components/home/AmazingDiscount.vue";
export default {
  name: "Home",
  components: {
    ResponsiveBannerDouble,
    // RecommendedProduct,
    HomeCategories,
    Services,
    ResponsiveBanner,
    SingleBanner,
    RecommendedProductSlider,
    AboutHome,
    ProductCategories,
    Header,
    Banners,
    Flashes,
    Weblogs,
    LastInstaPosts,
    ScrollToTop,
    Newbanner,
    MainBanners,
    specialCategories,
    AmazingDiscount
  },

  data() {
    return {
      showInstagram: true,
      reRenderInstagramFunction: debounce(this.reRenderInstagram, 250),
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.reRenderInstagramFunction);
  },
  mounted() {
    window.removeEventListener("resize", this.reRenderInstagramFunction);
    window.addEventListener("resize", this.reRenderInstagramFunction);
  },
  methods: {
  
    reRenderInstagram() {
      console.log("oki");
      this.showInstagram = false;
      this.$nextTick(() => {
        this.showInstagram = true;
      });
    },
  },
  computed: {
    suggestions() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return null;
      } else {
        return   this.$store.getters["front/getHomeData"].suggestions
      }
    },
    mostSales() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return null;
      } else {
        return this.$store.getters["front/getHomeData"].mostSales
        
      }
    },
    new_products() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return null;
      } else {
        return this.$store.getters["front/getHomeData"].new_products
       
      }
    },
    mostDiscount() {
      if (this.$store.getters["front/getHomeData"] == null) {
        return null;
      } else {
        return   this.$store.getters["front/getHomeData"].mostDiscount
      }
    },
    homeData() {
      if (this.$store.getters["front/getHomeData"] != null) {
        return this.$store.getters["front/getHomeData"];
      } else {
        return null;
      }
    },
    banners() {
      if (this.$store.getters["front/getHomeData"] != null) {
        let advertise = this.$store.getters["front/getHomeData"].advertise;
        return [
          [advertise.banner_1, advertise.banner_2],
          [advertise.banner_3, advertise.banner_4],
        ];
      } else {
        return [
          [null, null],
          [null, null],
        ];
      }
    },
  },
};
</script>
<style scoped>
</style>
